<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="12" xl="12">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-format-list-bulleted-square</v-icon>
          Categorias
          <v-spacer></v-spacer>
          <v-text-field
            v-model="categoryPagination.search"
            class="mr-3"
            append-icon="mdi-magnify"
            label="Buscar"
            placeholder="Informe o nome da categoria"
            single-line
            hide-details
            @keydown.enter="buscar()"
            @click:append="buscar()"
          />
          <v-spacer></v-spacer>
          <v-switch
            v-model="categoriesSearch.active"
            :label=" `Exibindo ${categoriesSearch.active? 'Ativos': 'Inativos'}`"
            single-line
            hide-details
            class="mr-3"
            style="width: 220px"
            @change="buscar()"
          ></v-switch>
          <v-btn color="#3D1159" dark :to="{ name: 'categoriesAdd' }">
            <v-icon left>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="categoriesSearch.items"
          :options.sync="options"
          :server-items-length="categoriesSearch.totalItems"
          item-key="id"
          class="elevation-1"
          v-if="categoriesSearch.items"
          :footer-props="{
                        'items-per-page-options': [10, 25, 50, 75, 100]
                      }"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#3D1159"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: 'category',
                    params: { id: item.id },
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
          <template v-slot:item.value="{ item }">
            <span>
              {{ item.value | money }}
            </span>
          </template>
        </v-data-table>

        <v-row v-if="!categoriesSearch.items">
          <v-col sm="12">
            <v-alert :value="true" type="warning" outlined>
              Nenhuma categoria encontrada
            </v-alert>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {CATEGORIES_GET, CATEGORY_SEARCH} from "@/store/actions/categories.type";
import {SET_CATEGORY_PAGINATION} from "@/store/mutations/categories.type";

export default {
  name: "categoriesList",

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    // await store.dispatch(CATEGORY_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(CATEGORIES_GET);
    return next();
  },

  data: () => ({
    selected: [],
    headers: [
      {text: "Nome", value: "name", width: 150},
      {text: "Valor", value: "value", width: 150},
      {
        text: "Visualizar",
        value: "actions",
        sortable: false,
        align: "center",
        width: 150
      },
    ],
    options: {}
  }),
  methods: {
    //Methods Lista Usuarios
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.dados.slice();
    },
    categoryFormValidation() {
      return this.$refs.categoryForm.validate();
    },
    buscar() {
      const {sortBy, sortDesc, itemsPerPage, page} = this.options

      store.commit(SET_CATEGORY_PAGINATION, {
        page: page,
        itemsPerPage: itemsPerPage,
        orderBy: sortBy,
        sortDesc: sortDesc[0]
      })
      store.dispatch(CATEGORY_SEARCH);
    }
  },
  watch: {
    options: {
      handler() {
        this.buscar()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["categoriesSearch", "checkUsers", "categoryPagination"]),
  },
};
</script>
