var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-format-list-bulleted-square")]),_vm._v(" Categorias "),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Buscar","placeholder":"Informe o nome da categoria","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscar()},"click:append":function($event){return _vm.buscar()}},model:{value:(_vm.categoryPagination.search),callback:function ($$v) {_vm.$set(_vm.categoryPagination, "search", $$v)},expression:"categoryPagination.search"}}),_c('v-spacer'),_c('v-switch',{staticClass:"mr-3",staticStyle:{"width":"220px"},attrs:{"label":("Exibindo " + (_vm.categoriesSearch.active? 'Ativos': 'Inativos')),"single-line":"","hide-details":""},on:{"change":function($event){return _vm.buscar()}},model:{value:(_vm.categoriesSearch.active),callback:function ($$v) {_vm.$set(_vm.categoriesSearch, "active", $$v)},expression:"categoriesSearch.active"}}),_c('v-btn',{attrs:{"color":"#3D1159","dark":"","to":{ name: 'categoriesAdd' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1)],1),(_vm.categoriesSearch.items)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.categoriesSearch.items,"options":_vm.options,"server-items-length":_vm.categoriesSearch.totalItems,"item-key":"id","footer-props":{
                      'items-per-page-options': [10, 25, 50, 75, 100]
                    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#3D1159","icon":"","to":{
                  name: 'category',
                  params: { id: item.id },
                }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])]}},{key:"item.value",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("money")(item.value))+" ")])]}}],null,false,4036116381)}):_vm._e(),(!_vm.categoriesSearch.items)?_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-alert',{attrs:{"value":true,"type":"warning","outlined":""}},[_vm._v(" Nenhuma categoria encontrada ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }